import * as React from "react";
import { observer } from "mobx-react";
import { TopNeighborhoodsCarouselProps } from "../typings";
import TopNeighborhoodsCarousel from "./TopNeighborhoodsCarousel";
import { withStores } from "stores";

/**
 * This module should be deprecated in favor of the Carousel component,
 * which is now completely driven by the backend module.
 *
 * In particular, the `isClickable` prop has been introduced to leave the functionality of
 * this component unchanged for backward compatibility
 * (the legacy neighborhood_with_dx_hsr view render non-clickable cards by default)
 *
 */
export const TopNeighborhoods = withStores(
  "context",
  "analytics",
  "flexModuleModelStore"
)(
  observer((props: TopNeighborhoodsCarouselProps) => {
    return <TopNeighborhoodsCarousel {...props} isClickable={true} />;
  })
);

export default TopNeighborhoods;
